body {
  margin: 0;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 20px;
  font-weight: 500;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


html, body, #root {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  text-rendering: optimizeLegibility !important;
  color: #232323;
  -webkit-font-smoothing: antialiased !important;
}

.formInput {
  font-size: 25px;
  border: 2px solid #d3d3d3;
  color: #686868;
  font-family: Georgia, 'Times New Roman', Times, serif;
  padding:10px;
}

.button {
  font-size:20px;
  padding:10px;
}

.formElements {
  margin-top: 5px;
}

.feedItem {
  width: 100%;
  padding-bottom: 20px;
  margin-top: 20px;
  border-bottom: 1px solid lightgray;
}

.feedItemAge {
  color: grey;
  margin-left:10px;
}

.commentsLink {
  color: grey;
}

.feedUserName {
  font-weight: bold;
  margin-right: 10px;
}

.feedListTitle {
  margin-right: 10px;
}

.feedBottomLine {
  display: flex;
  flex-direction: row;
}

.feedProfilePhoto {
  max-width: 100px;
}


@media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
  .feedProfilePhoto {
    max-width: 50px;
  }
}
